<!--客户管理-商家信息上传-->
<template>
  <div class="abnormal_data_page">
    <!-- 工具条 -->
    <el-form :inline="true" size="mini">
      <el-form-item>
        <el-input
          v-model="postdata.LocaleName"
          placeholder="监测点"
          @clear="searchData"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          v-model="PanicRangeTime"
          @change="searchData"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="searchData"
          >查询</el-button
        >
        <el-button type="primary" size="mini" @click="handleAdd"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      :data="dataObj.list"
      size="mini"
      border
      highlight-current-row
      :max-height="clientHeight"
      v-loading="loading"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" align="center" width="55" />
      <el-table-column
        prop="LocaleName"
        label="监测点"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="LocaleAddr"
        label="安装地址"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="CreatedAt"
        label="提交日期"
        width="180"
        align="center"
      />
      <el-table-column
        prop="UploadType"
        label="上报内容"
        width="200"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.UploadType | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="BeginDate"
        label="起始日期"
        width="180"
        align="center"
      />
      <el-table-column
        prop="EndDate"
        label="终止日期"
        width="180"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleLook(row)" size="mini"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 工具条 -->
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="postdata.page"
        :page-sizes="[20, 40, 100]"
        :page-size="postdata.perPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total"
      >
      </el-pagination>
    </div>
    <!--新增、查看界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
    >
      <el-form
        :model="formData"
        label-width="140px"
        :rules="formRule"
        ref="form"
        size="mini"
      >
        <el-form-item prop="LocaleId" label="监测点">
          <template v-if="dlg.title == '查看'">
            <el-input
              placeholder="请输入内容"
              v-model="formData.LocaleName"
              :disabled="true">
            </el-input>
          </template>
          <template v-else>
            <el-select
              v-model="formData.LocaleId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入监测点名称"
              :remote-method="remoteMethod">
              <el-option
                v-for="item in localeList"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="formData.BeginDate"
            value-format="yyyy-MM-dd"
            type="date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            v-model="formData.EndDate"
            value-format="yyyy-MM-dd"
            type="date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="UploadType" label="类型">
          <el-select v-model="formData.UploadType">
            <el-option label="净化器清洗" value="FilterWash"></el-option>
            <el-option
              label="歇业、停业申请"
              value="BusinessStopped"
            ></el-option>
            <el-option
              label="投诉"
              value="Complaint"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="Remark" label="备注">
          <el-input v-model.trim="formData.Remark" />
        </el-form-item>
        <el-form-item>
          <el-upload
            accept=".jpg,.jpeg,.png"
            :show-file-list="false"
            :action="upload()"
            :on-success="handleAvatarSuccess"
            :limit="4"
          >
            <i class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip">上传照片（最多4张，支持jpg、jpeg、png）</div>
          </el-upload>
        </el-form-item>
        <div class="img-list" v-if="fileList">
          <div
            class="single-img"
            v-for="(item, idx) in fileList"
            :key="idx"
            style="
              position: relative;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <img :src="item" />
            <div class="remove-img el-icon-close" @click="removeImg(idx)"></div>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visible = false"
          >取消</el-button
        >
        <el-button v-if="dlg.title === '新增'" type="primary" size="mini" @click.native="handleSubmit"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { parseTime } from '@/util/index'
import conf from '@/config'

export default {
  components: {},
  data() {
    return {
      postdata: {
        Page: 1, // 页码，自用
        PerPage: 20, // 页数，自用
        StartAt: 0, // 起始偏移量
        Size: 10, // 每页显示数量
        LocaleName: '', // 监测点名称
        Type: null, // 设备状态 2 // 异常离线；3 // 数据异常偏大；4 // 数据异常偏小；5 // 在线时间短；6 // 数据漂移异常
        OwnerId: [], // 所属单位
        RecordAtStart: '', // 异常日期开始时间
        RecordAtEnd: '', // 异常日期结束时间
        Status: null, // 操作状态 1 // 异常数据 待处理；2 // 异常数据 已派单；3 // 异常数据 正常
        HandleAtStart: '', // 处理日期开始时间
        HandleAtEnd: '' // 处理日期结束时间
      },
      PanicRangeTime: [], // 异常日期时间，自用
      loading: false,
      dataObj: {
        list: []
        // pages: 0,
        // total: 0,
      },
      dlg: {
        visible: false,
        title: ''
      },
      formData: {},
      localeList: [],
      fileList: [],
      changeState: false,
      formRule: {
        LocaleId: [
          { required: true, message: '请选择监测点', trigger: 'change' }
        ],
        UploadType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['customerTree','clientHeight']),
    ...mapState({
      customerProps: (state) =>
        Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
    })
  },
  mounted() {
    this.netGetMessageList() // 获取列表
  },
  filters: {
    typeFilter(type) {
      const op = {
        FilterWash: '净化器清洗',
        BusinessStopped: '歇业、停业申请',
        Complaint: '投诉'
      }
      return op[type]
    }
  },
  methods: {
    /**
     * @description 日期格式化
     */
    dateFormatter(row, column) {
      return parseTime(row[column.property], '{y}-{m}-{d}')
    },
    remoteMethod(queryString) {
      if (queryString !== '') {
        setTimeout(() => {
          const par = {"StartAt":0,"Size":100,"Param":{"Name":queryString}}
          this.$post('admin/listLocale', par).then((res) => {
            this.localeList = res.content
          }).catch(() => {})
        }, 300);
      } else {
        this.localeList = [];
      }
    },

    /**
     * @description 获取列表
     */
    netGetMessageList() {
      this.loading = true
      const postdata = JSON.parse(JSON.stringify(this.postdata))
      if (this.PanicRangeTime && this.PanicRangeTime.length > 0) {
        postdata.RecordAtStart = this.PanicRangeTime[0]
        postdata.RecordAtEnd = this.PanicRangeTime[1]
      }
      postdata.OwnerId = postdata.OwnerId[postdata.OwnerId.length - 1] * 1

      postdata.StartAt = postdata.perPage * (postdata.page - 1)
      postdata.Size = postdata.perPage

      for (const key in postdata) {
        if (postdata[key] === '') {
          delete postdata[key]
        }
      }
      this.$post('admin/queryLocaleUploadedInfo', postdata)
        .then((res) => {
          //console.log(res)
          this.dataObj.list = res.content
          this.dataObj.Page = Math.ceil(res.total / this.postdata.perPage);
          this.dataObj.total = res.total;
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    /**
     * @description 查询
     */
    searchData() {
      this.postdata.page = 1
      this.netGetMessageList()
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(pageSize) {
      this.postdata.page = 1
      this.postdata.perPage = pageSize
      this.netGetMessageList()
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.postdata.page = page
      this.netGetMessageList()
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.visible = true
      this.fileList = []
      this.localeList = []
      this.formData = {}
    },
    handleLook: function(row) {
      this.dlg.title = '查看'
      this.dlg.visible = true
      this.fileList = row.LocaleUploadedInfoPicS
      this.formData = Object.assign({}, row)
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      if (this.fileList.length < 4) {
        this.fileList.push(res.Data)
      } else {
        this.$message.warning(`照片数量限制在4张`)
      }
    },
    removeImg(idx) {
      //   this.changeState = true;
      this.fileList.splice(idx, 1)
    },
    handleSubmit: function() {
      //   if (!this.changeState) {
      //     this.dlg.visible = false;
      //     return;
      //   }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              const para = Object.assign({}, this.formData)
              para.LocaleUploadedInfoPicS = this.fileList
              this.$post('admin/createLocaleUploadedInfo', para)
                .then(() => {
                  this.netGetMessageList()
                  this.dlg.visible = false
                })
                .catch(() => {})
            })
            .catch(() => {})
        }
      })
    },
    handlerClose: function() {
      //   if (this.changeState) {
      //     this.changeState = false;
      //     this.netGetMessageList();
      //   }
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.abnormal_data_page {
  .page_box {
    padding: 30px 0;
    text-align: center;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.single-img > img {
  width: 160px;
  height: 160px;
}
.view-img {
  width: 178px;
  height: 178px;
  margin-right: 10px;
}
.remove-img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
}
.remove-img:hover {
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
</style>
